import { MarkerType } from "../model/MarkerType";

const yellowMarkerColor = "#febf27";
const blackMarkerColor = "#3f3f3f";
const darkBlueMarkerColor = "#5584f8";
const lightBlueMarkerColor = "#5781fb";
const grayMarkerColor = "#b4b4b4";

const MARKER_TYPE_COLOR_MAP = {
    [MarkerType.CUSTOMER]: yellowMarkerColor,
    [MarkerType.FIELD]: yellowMarkerColor,
    [MarkerType.LOADING_POINT]: yellowMarkerColor,
    [MarkerType.LABEL_ANCHOR]: yellowMarkerColor,
    [MarkerType.TRACKING]: blackMarkerColor,
    [MarkerType.LOADING_AND_UNLOADING_POINT]: blackMarkerColor,
    [MarkerType.OTHER]: darkBlueMarkerColor,
    [MarkerType.UNLOADING_POINT]: darkBlueMarkerColor,
    [MarkerType.ORDER]: lightBlueMarkerColor,
    [MarkerType.DISABLED]: grayMarkerColor,
};

export function getDefaultColorForMarkerType(type: MarkerType): string {
    return MARKER_TYPE_COLOR_MAP[type];
}
